import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CFixedImg,
  CVertCard,
  LContact,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'ACCESS',
            sub: '電車でのアクセス',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/access/train/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/access/train/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '当ホテルについて',
                path: '/about/',
              },
              {
                label: 'アクセス',
                path: '/about/access/',
              },
            ],
            current: {
              label: '電車でのアクセス',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <CFixedImg
            exClass="u_mb20"
            width={840}
            img={{
              src: '/assets/images/about/access/train/img_train.png',
              alt: '',
            }}
          />
          <ul
            className="c_noteList u_mb40 u_centerring"
            style={{ maxWidth: '840px' }}
          >
            <li>
              所要時間は目安です。乗り換え、待ち時間は含まれておりません。また運行状況により多少異なります。
            </li>
            <li>
              最寄り駅は、JR・市営地下鉄「桜木町駅」またはみなとみらい線「みなとみらい駅」です。
            </li>
          </ul>

          <CFixedImg
            exClass="u_mb40"
            width={840}
            img={{
              src: '/assets/images/about/access/train/img_train02.png',
              alt: '',
            }}
          />
          <h2 className="c_headingLv2 u_mb20">
            みなとみらい線「みなとみらい駅」からお越しの方
          </h2>
          <h3 className="c_headingLv5 u_mb25 u_fwn">
            1.
            みなとみらい駅「ランドマークタワー・クイーンズスクエア方面改札」を出ます。
          </h3>
          <h3 className="c_headingLv5 u_mb25 u_fwn">
            2. 左手の長いエスカレーターで1Fへ。
          </h3>
          <h3 className="c_headingLv5 u_mb25 u_fwn">
            3. 右方向へ直進し、ランドマークプラザ1F「サカタのタネ
            ガーデンスクエア」までお越しください。
          </h3>
          <h3 className="c_headingLv5 u_mb15 u_fwn">
            4.
            進行方向に向かって左側が「ホテル棟入口」、右側が「宴会棟入口」です。
          </h3>
          <ul className="c_noteList u_mb25">
            <li>
              「ホテル棟入口」は、カフェ「ラ・メゾン
              アンソレイユターブル」の隣奥にございます。
            </li>
            <li>
              「宴会棟入口」は、スーパーマーケット「成城石井」の隣奥にございます。
            </li>
          </ul>
          <CVertCard
            data={[
              {
                img: {
                  src: '/assets/images/about/access/train/img_train03.png',
                  alt: '',
                },
                title: '1F ホテル棟入口',
              },
              {
                img: {
                  src: '/assets/images/about/access/train/img_train04.png',
                  alt: '',
                },
                title: '1F 宴会棟入口',
              },
            ]}
          />
          <h2 className="c_headingLv2 u_mb20">
            JR・市営地下鉄「桜木町駅」から
            <br className="u_sp" />
            お越しの方
          </h2>
          <h3 className="c_headingLv5 u_mb10">＜ホテル棟へお越しの方＞</h3>
          <p className="c_headingLv5 u_mb25 u_fwn">
            1. 桜木町駅より、動く歩道終点右側の「3F
            ホテル入口」よりお入りください。
          </p>
          <p className="c_headingLv5 u_mb25 u_fwn">
            2. エスカレーターにて1Fまでお越しいただくと「フロントロビー」です。
          </p>
          <CVertCard
            data={[
              {
                img: {
                  src: '/assets/images/about/access/train/img_train05.png',
                  alt: '',
                },
                title: (
                  <>
                    3F ホテル入口
                    <br />
                    （動く歩道終点）
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/about/access/train/img_train06.png',
                  alt: '',
                },
                title: '3F エントランス内',
              },
              {
                img: {
                  src: '/assets/images/about/access/train/img_train07.png',
                  alt: '',
                },
                title: '1F フロントロビー',
              },
            ]}
          />
          <h3 className="c_headingLv5 u_mb10">＜宴会棟へお越しの方＞</h3>
          <p className="c_headingLv5 u_mb25 u_fwn">
            1. 桜木町駅を下車し、動く歩道終点まで進みます。
          </p>
          <p className="c_headingLv5 u_mb25 u_fwn">
            2. ランドマークプラザへ入り1Fへ下ります。
          </p>
          <p className="c_headingLv5 u_mb25 u_fwn">
            3.
            スーパーマーケット「成城石井」とエレベーターの間が「宴会棟入口」です。
          </p>
          <CVertCard
            data={[
              {
                img: {
                  src: '/assets/images/about/access/train/img_train04.png',
                  alt: '',
                },
                title: '1F 宴会棟入口',
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
